import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded', function() {

    document.getElementById('start-btn').addEventListener('click', function(e) {
        e.preventDefault();
        document.body.classList.add('signup');
    });
    document.querySelector('.close-signup').addEventListener('click', function (e) {
        e.preventDefault();
        document.body.classList.remove('signup');
    });
    if (document.querySelector('.learn-more-btn')) {
        document.querySelector('.learn-more-btn').addEventListener('click', function(e) {
            e.preventDefault();
            document.querySelector('#section-2').scrollIntoView({ behavior: 'smooth' });
        });
    }

    if (document.querySelector('.learn-book-btn')) {
        document.querySelector('.learn-book-btn').addEventListener('click', function(e) {
            e.preventDefault();
            document.querySelector('#section-book').scrollIntoView({ behavior: 'smooth' });
        });
    }

    /* Cookie banner */
    document.querySelector('#cookie-banner .close-banner').addEventListener('click', function (e) {
        e.preventDefault();
        var banner = document.querySelector('#cookie-banner');
        banner.classList.add('closing');
        setTimeout(function() {
            banner.parentNode.removeChild(banner);
        }, 1000);
    });
    document.querySelector('#cookie-banner .configure').addEventListener('click', function (e) {
        e.preventDefault();
        document.body.classList.add('modal-open');
    })
    document.querySelector('#cookie-preferences .close-modal').addEventListener('click', function (e) {
        e.preventDefault();
        document.body.classList.remove('modal-open');
    });
    document.querySelectorAll('.dropdown-wrapper > button').forEach(function (el) {
        el.addEventListener('click', function (e) {
            e.preventDefault();
            el.parentNode.classList.toggle('active');
        });
    });
    document.querySelector('#ga-cookie-checkbox').addEventListener('change', function (e) {
        window['ga-disable-' + window.ga_tracking] = !this.checked;
    });
});
